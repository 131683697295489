<template>
  <div class="lightbox-user-profile">
    <h3>Contact</h3>

    <div class="info" v-for="info in contactInfo" :key="info.field">
      <div class="info-field">
        <p>{{ info.field }}</p>
      </div>
      <div class="info-value">
        <p>{{ info.value }}</p>
      </div>
    </div>

    <h3>Company</h3>

    <div class="info" v-for="info in companyInfo" :key="info.field">
      <div class="info-field">
        <p>{{ info.field }}</p>
      </div>
      <div class="info-value">
        <p>{{ info.value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LightboxUserProfile",
  components: {},
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      contactInfo: [],
      companyInfo: [],
    };
  },
  methods: {
    setInfo() {
      const profile = this.$props.user.profile;
      this.contactInfo = [
        {
          field: "Full Name",
          value: `${profile.first_name} ${profile.last_name}`,
        },
        {
          field: "Phonenumber",
          value: profile.phonenumber,
        },
        {
          field: "Email",
          value: this.$props.user.email,
        },
      ];
      this.companyInfo = [
        {
          field: "Company name",
          value: profile.company_name,
        },
        {
          field: "Company KVK",
          value: profile.company_kvk,
        },
        {
          field: "Address",
          value: `${profile.billing_address} ${profile.billing_address_number}`,
        },
        {
          field: "City",
          value: profile.billing_city,
        },
        {
          field: "Country",
          value: profile.billing_country,
        },
      ];
    },
  },
  mounted() {
    this.$emit("setLightboxInfo", {
      title: "User Profile",
    });

    this.setInfo();
  },
};
</script>

<style lang="scss">
.lightbox-user-profile {
  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .info {
    display: flex;
    margin-bottom: 15px;

    &-field {
      text-align: right;
      padding: 15px 15px 15px 0px;
      width: 225px;
      background-color: #ff1e1e;
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;

      p {
        color: #fff;
        font-weight: bold;
      }
    }
    &-value {
      padding: 15px;
      width: 100%;
      background-color: #f9f9f9;
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
    }
  }
}
</style>
